<template>
  <div>
    <!-- 头部 -->
    <section class="section panel servicehome_header">
      <div class="col-lg-12 container_max">
        <header
          class="T48px_Regular colorfff header_title wow animate__fadeInUp" 
          :class=" $t('servicehome.header_title1') == 'User-' ? 'header_title_max' : ''"
          data-wow-offset="-10"
        >
          <span>{{ $t("servicehome.header_title1") }}</span>
          <span class="color1D73FF">{{ $t("servicehome.header_title2") }}</span>
          <span>{{ $t("servicehome.header_title3") }}</span>
          <span class="color1D73FF">{{ $t("servicehome.header_title4") }}</span>
          <span> {{ $t("servicehome.header_title5") }}</span>
          <span class="color1D73FF">{{ $t("servicehome.header_title6") }}</span>
          <span> {{ $t("servicehome.header_title7") }}</span>
          <span class="color1D73FF">{{ $t("servicehome.header_title8") }}</span>
        </header>

        <div class="col-lg-12 flex-w">
          <div
            class="col-lg-6 wow animate__fadeInUp"
            @click="routerBtn('/software')"
          >
            <div class="flex-w info_item_box">
              <div class="left_box flex-c">
                <header class="T24px_Regular info_item_header">
                  <span class="color1D73FF">
                    {{ $t("servicehome.software_title1") }}
                  </span>
                  <span class="colorfff">
                    {{ $t("servicehome.software_title2") }}
                  </span>
                </header>
                <div class="flex-c">
                  <span class="label_bj T18px_Regular colorfff">
                    {{ $t("servicehome.software_chil1") }}
                  </span>
                  <span class="label_bj T18px_Regular colorfff">
                    {{ $t("servicehome.software_chil2") }}
                  </span>
                  <span class="label_bj T18px_Regular colorfff">
                    {{ $t("servicehome.software_chil3") }}
                  </span>
                  <span class="label_bj T18px_Regular colorfff">
                    {{ $t("servicehome.software_chil4") }}
                  </span>
                  <span class="label_bj T18px_Regular colorfff">
                    {{ $t("servicehome.software_chil5") }}
                  </span>
                  <span class="label_bj T18px_Regular colorfff">
                    {{ $t("servicehome.software_chil6") }}
                  </span>
                </div>

                <footer class="T14px_Regular flex-wb footer_info">
                  <span class="color1D73FF">
                    {{ $t("servicehome.ljie") }}
                  </span>
                  <img
                    class="jq_icon"
                    src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/jq.png"
                    alt=""
                  />
                </footer>
              </div>

              <img
                class="info_item_box1_logo"
                src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/info_item_box1.png"
                alt=""
              />
            </div>
          </div>
          <div
            class="col-lg-6 flex-wr wow animate__fadeInUp"
            @click="routerBtn('/website')"
          >
            <div class="flex-w info_item_tb_box">
              <div class="left_box col-12 flex-c">
                <header class="T24px_Regular info_item_header">
                  <span class="color1D73FF">
                    {{ $t("servicehome.website_title1") }}
                  </span>
                  <span class="colorfff">
                    {{ $t("servicehome.website_title2") }}
                  </span>
                </header>
                <div class="flex-w">
                  <span class="label_bj T18px_Regular colorfff">
                    {{ $t("servicehome.website_chil1") }}
                  </span>
                  <span class="label_bj T18px_Regular colorfff">
                    {{ $t("servicehome.website_chil2") }}
                  </span>
                  <span class="label_bj T18px_Regular colorfff">
                    {{ $t("servicehome.website_chil3") }}
                  </span>
                  <span class="label_bj T18px_Regular colorfff">
                    {{ $t("servicehome.website_chil4") }}
                  </span>
                  <span class="label_bj T18px_Regular colorfff">
                    {{ $t("servicehome.website_chil5") }}
                  </span>
                  <span class="label_bj T18px_Regular colorfff">
                    {{ $t("servicehome.website_chil6") }}
                  </span>
                </div>
              </div>

              <img
                class="info_item_box2_logo"
                src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/info_item_box2.png"
                alt=""
              />

              <footer class="T14px_Regular flex-wb footer_info">
                <span class="color1D73FF">{{ $t("servicehome.ljie") }}</span>
                <img
                  class="jq_icon"
                  src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/jq.png"
                  alt=""
                />
              </footer>
            </div>
          </div>
          <div
            class="col-lg-6 wow animate__fadeInUp"
            data-wow-duration="1.5s"
            @click="routerBtn('/brand')"
          >
            <div class="flex-w info_item_tb_box">
              <div class="left_box col-12 flex-c">
                <header class="T24px_Regular info_item_header">
                  <span class="color1D73FF">
                    {{ $t("servicehome.brand_title1") }}
                  </span>
                  <span class="colorfff">
                    {{ $t("servicehome.brand_title2") }}
                  </span>
                </header>
                <div class="flex-w">
                  <span class="label_bj T18px_Regular colorfff">
                    {{ $t("servicehome.brand_chil1") }}
                  </span>
                  <span class="label_bj T18px_Regular colorfff">
                    {{ $t("servicehome.brand_chil2") }}
                  </span>
                  <span class="label_bj T18px_Regular colorfff">
                    {{ $t("servicehome.brand_chil3") }}
                  </span>
                  <span class="label_bj T18px_Regular colorfff">
                    {{ $t("servicehome.brand_chil4") }}
                  </span>
                  <span class="label_bj T18px_Regular colorfff">
                    {{ $t("servicehome.brand_chil5") }}
                  </span>
                  <span class="label_bj T18px_Regular colorfff">
                    {{ $t("servicehome.brand_chil6") }}
                  </span>
                </div>
              </div>

              <div class="col-12 flex-wc">
                <img
                  class="info_item_box3_logo"
                  src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/info_item_box3.png"
                  alt=""
                />
              </div>

              <footer class="T14px_Regular flex-wb footer_info">
                <span class="color1D73FF">{{ $t("servicehome.ljie") }}</span>
                <img
                  class="jq_icon"
                  src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/jq.png"
                  alt=""
                />
              </footer>
            </div>
          </div>
          <div
            class="col-lg-6 flex-wr wow animate__fadeInUp"
            data-wow-duration="1.5s"
          >
            <div class="flex-w info_item_tb_box">
              <div class="left_box col-12 flex-c">
                <header class="T24px_Regular info_item_header">
                  <span class="color1D73FF">
                    {{ $t("servicehome.touch_title1") }}
                  </span>
                  <span class="colorfff">
                    {{ $t("servicehome.touch_title2") }}
                  </span>
                </header>
                <div class="flex-w">
                  <span class="label_bj T18px_Regular colorfff">
                    {{ $t("servicehome.touch_chil1") }}
                  </span>
                  <span class="label_bj T18px_Regular colorfff">
                    {{ $t("servicehome.touch_chil2") }}
                  </span>
                  <span class="label_bj T18px_Regular colorfff">
                    {{ $t("servicehome.touch_chil3") }}
                  </span>
                </div>
              </div>

              <div class="col-12 flex-wc">
                <img
                  class="info_item_box4_logo"
                  src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/info_item_box4.png"
                  alt=""
                />
              </div>

              <footer class="T14px_Regular  flex-wb footer_info">
                <span class="color1D73FF">{{ $t("servicehome.ljie") }}</span>
                <img
                  class="jq_icon"
                  src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/jq.png"
                  alt=""
                />
              </footer>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 项目总结 -->
    <section class="section panel project_summarize_box">
      <div class="customized_box flex-wb">
        <div class="flex-c">
          <span class="T36px_Regular colorfff">
            {{ $t("customized.title") }}
            <span class="color1D73FF">{{ $t("customized.title1") }}</span>
          </span>
          <span class="T16px_Regular color818181">
            {{ $t("customized.tipe") }}
          </span>
        </div>
        <div class="flex-c">
          <span class="T14px_Regular colorfff tips_net">
            {{ $t("customized.tipe_ri") }}
          </span>
          <span
            @click="routerBtn('/contact')"
            class="T20px_Regular colorfff my_btn animation_right"
          >
            {{ $t("customized.btn") }}
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  data() {
    return {};
  },
  mounted() {
    const wow = new WOW();
    wow.init();
  },
  methods: {
    /**
     * 路由跳转方法
     * @param {*} id 文章id
     * @param {*} url 跳转路由
     */
    routerBtn(url, id) {
      if (id) {
        this.$router.push({ path: url, query: { id: id } });
        return;
      }
      this.$router.push({ path: url });
    },
  },
};
</script>

<style lang="less" scoped>
.servicehome_header {
  min-height: 100vh;
  background: #15171d;
  padding-top: 5.6875rem;
  padding-bottom: 0;

  .container_max {
    width: 100%;
    padding: 0 5.875rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .header_title {
      width: 24.0625rem;
      margin-bottom: 2.625rem;
    }
    .header_title_max{
       width: 44.0625rem;
    }

    .info_item_box {
      width: 22.375rem;
      min-height: 22.375rem;
      background: #282b35;
      margin-bottom: 3.4688rem;

      .info_item_header {
        margin-bottom: 1.25rem;
      }

      .left_box {
        padding-left: 1.7813rem;
        min-height: 22.375rem;
        justify-content: space-around;

        .label_bj {
          width: fit-content;
          padding: 0.1888rem .5rem;
          border-radius: 1.75rem;
          border: 0.0313rem solid #ffffff;
          margin-bottom: 0.9375rem;
        }

        .footer_info {
          position: relative;
          letter-spacing: 0.125rem;

          &::before {
            position: absolute;
            bottom: -0.375rem;
            content: "";
            width: 1.0938rem;
            height: 0px;
            background: #1d73ff;
            border: 0.0313rem solid;
            border-image: linear-gradient(
                140deg,
                rgba(29, 115, 255, 1),
                rgba(1, 7, 17, 1),
                rgba(21, 23, 29, 1)
              )
              1 1;
          }

          .jq_icon {
            width: 0.875rem;
            height: 0.875rem;
          }
        }
      }

      .info_item_box1_logo {
        width: 12.5938rem;
        height: 20.1875rem;
      }
    }

    .info_item_tb_box {
      width: 22.375rem;
      min-height: calc(22.375rem - 2.5rem);
      max-height: 22.375rem;
      background: #282b35;
      padding: 1.25rem 0.8438rem 1.25rem 1.4063rem;
      margin-bottom: 3.4688rem;

      .info_item_header {
        margin-bottom: 1.25rem;
      }

      .label_bj {
        width: fit-content;
        padding: 0.1888rem .5rem;
        border-radius: 1.75rem;
        border: 0.0313rem solid #ffffff;
        margin-bottom: 0.6875rem;
        margin-right: 1.0313rem;

        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      .info_item_box2_logo {
        width: 20.1875rem;
        height: 12.5938rem;
      }

      .info_item_box3_logo {
        width: 9.5938rem;
        height: 13.125rem;
        margin: 0 auto;
      }

      .info_item_box4_logo {
        width: 10.0625rem;
        height: 11.875rem;
        margin-bottom: 2.1875rem;
        margin-top: 0.625rem;
      }

      .footer_info {
        position: relative;
        letter-spacing: 0.125rem;

        &::before {
          position: absolute;
          bottom: -0.375rem;
          content: "";
          width: 1.0938rem;
          height: 0px;
          background: #1d73ff;
          border: 0.0313rem solid;
          border-image: linear-gradient(
              140deg,
              rgba(29, 115, 255, 1),
              rgba(1, 7, 17, 1),
              rgba(21, 23, 29, 1)
            )
            1 1;
        }

        .jq_icon {
          width: 0.875rem;
          height: 0.875rem;
        }
      }
    }
  }
}

.project_summarize_box {
  background: #15171d;
  padding: 0;
  padding-bottom: 4.6875rem;
  // padding-top: 2.75rem;

  .customized_box {
    background: #15171d;
    margin: 0 5.875rem;
    // margin-top: 3.375rem;
    height: 7.9375rem;
    padding: 0 1.7813rem 0 1.7188rem;
    background-image: url("https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/dingzhi.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .tips_net {
      height: 0.8438rem;
    }

    .my_btn {
      width: 5.8125rem;
      height: 1.7813rem;
      border: 0.0313rem solid #ffffff;
      text-align: center;
      line-height: 1.7813rem;
    }
  }
}
</style>
